.background-skill{
    color: #bc4639;
}

.background-skill-2{
    color: #123c69;
}
.deep-rusted-red{
    color:#c1432e
}
.rusted-gold{
   color: #ce9e62
}


.transparentButton:focus{
    outline: none !important;
    box-shadow: none;
    border: none;
}
