.skills-right{
    right: 0;
    margin-right: 30%;
}

.skills-left{
    left:0;
    margin-left: 30%;
}
.experienceLabel{
    cursor:pointer;
}

.circle{
    border-radius: 50%;
    width: 38px;
    height: 38px;
    padding: 10px;
    border: 1.5px solid black;
    text-align: center;
    transition: 1s;
}

.circle:hover{
    color: black;
    background-color: #2d545e;
    border: 1.5px dotted black;

}
.expImg{
    max-width: 70% !important;
}
.expText{
    font-size: 1vw;

}


@media (max-width: 500px) {
    .expText{
        font-size: 3vw;
    }

  }
  
  .introTextSize{
    font-size: 2vw;
  }

  @media (max-width: 500px) {
    .introTextSize{
        font-size: 4vw;
    }

  }

  @media (max-width: 500px) {
    .introDivTextWidth{
        width: 50% !important;
        margin-top: 20px !important;
    }

  }

