body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.skills-right{
    right: 0;
    margin-right: 30%;
}

.skills-left{
    left:0;
    margin-left: 30%;
}
.experienceLabel{
    cursor:pointer;
}

.circle{
    border-radius: 50%;
    width: 38px;
    height: 38px;
    padding: 10px;
    border: 1.5px solid black;
    text-align: center;
    transition: 1s;
}

.circle:hover{
    color: black;
    background-color: #2d545e;
    border: 1.5px dotted black;

}
.expImg{
    max-width: 70% !important;
}
.expText{
    font-size: 1vw;

}


@media (max-width: 500px) {
    .expText{
        font-size: 3vw;
    }

  }
  
  .introTextSize{
    font-size: 2vw;
  }

  @media (max-width: 500px) {
    .introTextSize{
        font-size: 4vw;
    }

  }

  @media (max-width: 500px) {
    .introDivTextWidth{
        width: 50% !important;
        margin-top: 20px !important;
    }

  }


.background-skill{
    color: #bc4639;
}

.background-skill-2{
    color: #123c69;
}
.deep-rusted-red{
    color:#c1432e
}
.rusted-gold{
   color: #ce9e62
}


.transparentButton:focus{
    outline: none !important;
    box-shadow: none;
    border: none;
}

.hoverMouseRed:hover{
    width:50%;
    background-color: red;
    transition: 1s;
}


.line{
    opacity: 100;
    border: 2px solid #2d545e;
    -webkit-animation: slide 5s ease-in infinite;
            animation: slide 5s ease-in infinite;
}
.line1{
   
    -webkit-animation-delay: 1000ms;
   
            animation-delay: 1000ms;
}
.line2{

    -webkit-animation-delay: 800ms;

            animation-delay: 800ms;
}
.line3{

    -webkit-animation-delay: 500ms;

            animation-delay: 500ms;
}
.line4{

    -webkit-animation-delay: 700ms;

            animation-delay: 700ms;
}
.line5{

    -webkit-animation-delay: 300ms;

            animation-delay: 300ms;
}

@-webkit-keyframes slide
{
    0%   {width: 0%}
    50%  {width: 50%}
    100% {width: 0%}
}

@keyframes slide
{
    0%   {width: 0%}
    50%  {width: 50%}
    100% {width: 0%}
}

.intro-text{
    transition: 2s;
    font-size: 1.5em;
}

.intro-text:hover{
    font-size: 2em;
}

.introProfilePic{
    transition: 0.5s;
    max-width: 80%;
}

.introProfilePic:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.test{
    transition: 5s;
    opacity: 0;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

